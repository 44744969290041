<template>
  <div>
    <div class="event-detail vremynsk">
      <div class="vremynsk__top-content">
        <div class="content">
          <div class="event-detail__title" v-html="EventDetailData.name"></div>
        </div>
        <Breadcrumbs
          :pages="[
            {
              name: EventDetailData.name,
            },
          ]"
          :color="'#0194AC'"
        />
      </div>

      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="require('@/assets/img/agnostic-biomarker/banner2.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/agnostic-biomarker/banner2.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/agnostic-biomarker/banner2.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="require('@/assets/img/agnostic-biomarker/banner2.jpg')"
            />
          </picture>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__description" v-if="false">
          Уважаемые коллеги, приглашаем вас принять участие в вебинаре,
          посвященном особенностям тестирования статуса HER2 в опухолях
          желудочно-кишечного тракта. Эксперты в области HER2-диагностики
          солидных опухолей поделятся новейшими данными исследований и
          рекомендациями научных сообществ. Также вы сможете поучаствовать в
          мастер-классе с разбором сканов из реальной клинической практики
          ИГХ-диагностики рака желудка.
        </div>
        <div class="event-detail__timer mb-0 mt-8 mt-sm-0">
          <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Трансляция началась"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Мероприятие начнется через:"
            }}
          </div>
          <div
            class="event-detail__timer-date mt-sm-4"
            v-if="timerResult.months"
          >
            {{ $m(EventDetailData.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetailData.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div
              class="event-detail__timer-item pl-0"
              v-if="timerResult.days > 0"
            >
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-else-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_white-orange px-6"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-img">
              <img
                :src="require('@/assets/img/agnostic-biomarker/arrow.svg')"
              />
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                26 ноября, 10:00-11:00 (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.place">
              <div class="event-detail__date-item-title">Место</div>
              <div
                class="event-detail__date-item-value"
                v-html="EventDetailData.place"
              ></div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetailData.types">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                {{ EventDetailData.types[0].name }}
              </div>
            </div>
          </div>
        </div>
        <div class="lectors-block">
          <div class="lectors-title">Наши эксперты</div>
          <div class="lectors-grid">
            <div class="lectors-grid__item">
              <div class="lectors-grid__item-img">
                <img
                  :src="require('@/assets/img/agnostic-biomarker/ava2.jpg')"
                />
              </div>
              <div class="lectors-grid__item-info">
                <div class="lectors-grid__item-tag">Спикер</div>
                <div class="lectors-grid__item-name">
                  Чухрай Ольга Юрьевна
                </div>
                <div class="lectors-grid__item-description">
                  ГБУЗ «КОД № 1» МЗ КК г. Краснодар.
                  Заведующая патологоанатомическим отделением
                </div>
              </div>
            </div>
            <div class="lectors-grid__item">
              <div class="lectors-grid__item-img">
                <img
                  :src="require('@/assets/img/agnostic-biomarker/ava.jpg')"
                />
              </div>
              <div class="lectors-grid__item-info">
                <div class="lectors-grid__item-tag">Спикер</div>
                <div class="lectors-grid__item-name">
                  Раскин Григорий Александрович
                </div>
                <div class="lectors-grid__item-description">
                  д.м.н., профессор кафедры онкологии Медицинского института
                  СПбГУ, заместитель главного врача по лабораторной медицине
                  Медицинского института им. Березина Сергея, главный специалист
                  по патоморфологии Городского клинического онкологического
                  диспансера Санкт-Петербурга
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="lectors-block">
          <div class="lectors-title mb-10">Программа</div>
          <div
            v-if="program && program.length"
            v-html="programmHTML(program[0].items)"
          ></div>
        </div>
        <div class="event-detail__tip">
          <!-- HER2 – рецептор эпидермального фактора роста человека 2-го типа;
          <br />
          <br />
          ЖКТ – желудочно-кишечный тракт;
          <br />
          <br />
          ИГХ –иммуногистохимическое исследование. -->
        </div>
      </div>
    </div>
    <div
      class="event-detail__iframe mb-8"
      v-if="!Object.keys(timerResult).length"
      :class="{ 'event-detail__iframe_opened': openFrame }"
    >
      <div
        @click="openFrame = false"
        v-if="openFrame"
        class="event-detail__close-btn"
      >
        <span class="button button_pink button_small">Назад</span>
      </div>

      <iframe
        v-if="
          IframeAccess &&
          IframeAccess[`event_${EventDetailData.id}`] &&
          Object.keys(IframeAccess[`event_${EventDetailData.id}`]).length
        "
        class="d-xl-block"
        id="userplayer"
        frameborder="0"
        :src="
          IframeAccess[`event_${EventDetailData.id}`].url +
          '&lang=Ru-ru&disableSupport=1'
        "
        allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
        allowusermedia
      ></iframe>
      <iframe
        v-else
        class="d-xl-block"
        id="userplayer"
        frameborder="0"
        :src="`https://edu.livedigital.space/room/${
          EventDetailData.webinar_id
        }/?participantName=${
          $route.query.email || ''
        }&lang=Ru-ru&disableSupport=1`"
        allow="camera;microphone;fullscreen;accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture;display-capture;"
        allowusermedia
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
export default {
  metaInfo() {
    if (this.EventDetailData.name) {
      return {
        title:
          "Az-most - " + this.EventDetailData.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetailData.name };
    }
  },
  name: "EnhertuEvent",
  components: {
    Breadcrumbs,
  },
  data: () => ({
    timerResult: { id: 0 },
    interval: null,
    openFrame: false,
  }),
  computed: {
    ...mapGetters(["EventDetail", "user", "IframeAccess"]),
    EventDetailData() {
      return {
        id: 625,
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
        ],
        name: "Вебинар «Агностический биомаркер  HER2/neu - особенности диагностики гиперэкспресии в опухолях различных локализаций»",
        date: this.$route.meta.date || "2024-11-26T10:00:00+03:00",
        date_end: "2024-11-26T11:00:00+03:00",
        place: "",
        webinar_id: "zfrcaOPO9U",
        room_id: "671a35731629360c486cfd13",
      };
    },
    program() {
      return [
        {
          title: "Программа на 28 сентября",
          descr: "(указано московское время)",
          subdescr: "",
          items: [
            {
              time: "10:00 – 10:30",
              title:
                "Специфика исследования статуса HER2 в гинекологических и урологических опухолях",
              description: "Раскин Григорий Александрович",
              icon: "person.svg",
            },
            {
              time: "10:30 – 10:50",
              title:
                "Организационные вопросы ИГХ-тестирования на примере Краснодарского края",
              description: "Чухрай Ольга Юрьевна",
              icon: "person.svg",
            },
            {
              time: "10:50 – 11:00",
              title: "Дискуссия",
              icon: "talking.svg",
            },
          ],
        },
      ];
    },
  },
  methods: {
    ...mapActions(["fetchEventDetail", "fetchIframeAccess"]),
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="${require(`@/assets/img/agnostic-biomarker/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetailData.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    openIframe() {
      this.openFrame = true;
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  async mounted() {
    await this.fetchEventDetail({ id: this.EventDetailData.id });
  },
  watch: {
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        this.fetchIframeAccess(this.EventDetailData.id);
        clearInterval(this.interval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 0px;
  }

  &__description {
    margin-top: 32px;
    margin-bottom: 56px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: #3c4242;
  }

  &__tip {
    margin-top: 52px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    background: #ff672a;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1220px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    width: calc(100% - 30px);
    margin-left: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    padding-left: 160px;
    margin-top: 52px;
    margin-bottom: 40px;
    background-color: #fff;
    border: 1px solid #ebefee;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
      padding-left: 200px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 64px;
      padding-left: 24px;
      width: 100%;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 132px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-img {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);

    @media screen and (max-width: 1220px) {
      left: 30px;
    }

    @media screen and (max-width: 767px) {
      width: 60px;
      top: 0px;
      left: 0;

      & img {
        width: 100%;
      }
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }
  &__content {
    margin-top: 40px;
  }

  &__title {
    display: none;
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    @media screen and (min-width: 1220px) {
      color: #fff;
    }

    span {
      @media screen and (min-width: 1220px) {
        color: #ff7f32;
      }
    }

    @media screen and (max-width: 767px) {
      order: 2;
      display: block;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
      margin-top: 24px;
      span {
        @media screen and (min-width: 1220px) {
          color: #ff7f32;
        }
        // font-size: 21px;
        // line-height: 26px;
      }
    }
  }

  &__button {
    width: 200px;
    margin-top: auto;
    @media screen and (max-width: 767px) {
      order: 1;
      width: 100%;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px;
    color: #3c4242;
    @media screen and (min-width: 1220px) {
      color: #fff;
    }
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      height: auto;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      // background-color: #ccd7e0;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }

  & picture {
    width: 100%;
    height: auto;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    // position: absolute;
    // top: 0;
    // left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.lectors-block {
  margin-top: 64px;

  .lectors-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-size: 23px;
    font-weight: 400;
    line-height: 29px;
    color: #1f1f1f;
  }
  .lectors-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    @media screen and (max-width: 1220px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
    &__item {
      border-radius: 16px;
      height: 100%;

      // background-color: #ffe5d6;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 32px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      &-img {
        width: 183px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;

        @media screen and (max-width: 767px) {
          margin-bottom: 32px;
        }

        & img {
          width: 100%;
        }
      }
      &-tag {
        margin-bottom: 16px;
        border-radius: 8px;
        padding: 4px 8px;
        background-color: #fe6229;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #fff;
        width: fit-content;
      }
      &-name {
        margin-bottom: 8px;
        color: #3c4242;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      &-description {
        color: #626262;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      // padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #132647;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }
}
</style>